<template>
    <div class="ProgrammeSummary.vue">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form size="small" :inline="true" label-width="120px">
                <el-row>
                    <el-col :span="12">
                        <ef-start-date v-model="query.startTime" />
                    </el-col>
                    <el-col :span="12">
                        <ef-end-date v-model="query.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form label-width="120px" :inline="true" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组">
                            <el-input readonly :value="form.deptGroupName" style="width: 180px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="计划名称">
                            <el-input readonly :value="form.name" style="width: 180px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="商品名称">
                            <el-input readonly :value="form.goodsName" style="width: 180px" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="已售数量(含赠品)">
                            <el-input readonly :value="data.saleCount" style="width: 180px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="已领取数量">
                            <el-input readonly :value="data.getCount" style="width: 180px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="领取中">
                            <el-input readonly :value="data.deliveringCount" style="width: 180px" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleQuery" size="small">查询</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table :data="tableData" border stripe>
                <el-table-column label="机构" width="240" prop="name" />
                <el-table-column label="销售数量" width="160" prop="saleCount" />
                <el-table-column label="领取数量" width="160" prop="getCount" />
            </el-table>
        </el-card>
    </div>
</template>

<script>
import EfStartDate from '../../../components/EfStartDate';
import EfEndDate from '../../../components/EfEndDate';
import UrlUtils from '../../../../js/UrlUtils';

import qs from 'qs';
export default {
    name: 'ProgrammeSummary',
    props: ['form'],
    components: { EfEndDate, EfStartDate },
    data() {
        return {
            query: {
                startTime: null,
                endTime: null,
                code: this.form.code,
            },
            data: {
                saleCount: 0,
                getCount: 0,
                deliveringCount: 0,
            },
            tableData: [],
            depts: [],
            deptsInGroupUrl: '/system/dept/queryExtend',
        };
    },
    mounted() {
        const _this = this;
        UrlUtils.QueryRemote(
            this,
            this.deptsInGroupUrl + '?' + qs.stringify({ deptGroupCode: this.form.deptGroupCode }),
            (rst) => {
                const depts = rst;
                _this.depts = depts;
                _this.handleQuery();
            }
        );
    },
    methods: {
        handleQuery() {
            const _this = this;
            UrlUtils.QueryRemote(this, '/merchandising/summary?' + qs.stringify(this.query), (summary) => {
                this.data.saleCount = summary.saleCount;
                this.data.getCount = summary.getCount;
                this.data.deliveringCount = summary.deliveringCount;
                const dMap = {};
                const gMap = {};
                summary.deptSales.forEach((v) => {
                    dMap[v.first] = v.second;
                });
                summary.deptGets.forEach((v) => {
                    gMap[v.first] = v.second;
                });
                _this.tableData = _this.depts
                    .map((d) => {
                        d.saleCount = dMap[d.code];
                        d.getCount = gMap[d.code];
                        return d;
                    })
                    .filter((r) => r.saleCount || r.getCount);
            });
        },
    },
};
</script>
